import * as React from "react"
import 'antd/dist/antd.css';
import '../../styles/global.scss';
import IndentedContent from '../helpers/IndentedContent';
import Header from './Header'
import Container from '../Container';
import Footer from './Footer';

// markup
export default function Handbook(props) {
  return (
    <>
      <Container containerStyles={{ padding: 0, zIndex: 1 }}>
        <IndentedContent>
          <Header></Header>
        </IndentedContent>
      </Container>

      <main style={{zIndex: 0}}>

        {props.children}

      </main>
      <Footer></Footer>
    </>
  )
}
